<template>
  <div id="gallery_wrap">
    <carousel :items-to-show="1" :wrap-around="true">
      <slide v-for="img in gallery" v-bind:key="img.id">
        <img :src="img.url" />
      </slide>

      <template #addons>
        <navigation />
      </template>
    </carousel>
    <div></div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  components: { Carousel, Slide, Pagination, Navigation },
  props: ['items'],
  data() {
    return {
      gallery: [],
    };
  },
  name: 'GalleryCard',
  methods: {
    async getImage(id) {
      return this.axios
        .get(this.api.getMediaUrl + id+"?" + this.$store.getters['auth/getLanguageRest'])
        .then((res) => {
          return res.data.media_details.sizes;
        })
        .catch((err) => {
          console.log('Errror: ' + err);
        });
    },
    getGallery() {
      if (typeof this.items === 'undefined') return;
      if (this.items.length === 0) return;
      this.items.forEach((item) => {
        this.getImage(item).then((sizes) => {
          this.gallery.push({
            id: item,
            url: sizes.medium.source_url,
            alt: '',
            title: '',
          });
        });
      });
    },
  },
  mounted() {
    this.getGallery();
  },
};
</script>

<style scoped>
#gallery_wrap {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 90%;
}

img {
  width: 100%;
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
