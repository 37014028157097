<template>
  <div v-if="loading">{{ $gettext('Caricamento dei dati della macchina...') }}</div>

  <div class="machine__title no-main-padding" v-if="!loading">
    <h1>{{ title }}</h1>
  </div>

  <div v-if="!loading" class="machine">
    <div class="column">
      <picture v-if="headerImage">
        <source
          :srcset="headerImage.full.source_url"
          media="(min-width: 800px)"
        />

        <source
          :srcset="headerImage.full.source_url"
          media="(min-width: 600px)"
        />

        <img :src="headerImage.full.source_url" alt="Baby Sleeping" />
      </picture>
    </div>

    <div class="column column--text">
      <div class="machine__body" v-html="body"></div>
    </div>

    <div class="clearfix"></div>

    <gallery-card
      v-if="galleryItems.length > 0"
      :items="galleryItems"
    ></gallery-card>
    <div class="clearfix"></div>

    <div class="catalogo" v-if="catalogo != ''">
      <a :href="catalogo" target="_blank">{{ $gettext('Scopri di più') }}</a>
    </div>
  </div>
</template>

<script>
import galleryCard from '@/components/shared/GalleryCard';
export default {
  components: { galleryCard },

  data() {
    return {
      loading: false,
      headerImage: false,
      title: '',
      name: '',
      body: '',
      galleryItems: [],
      catalogo: '',
    };
  },

  name: 'machine-details',

  methods: {
    domDecoder(str) {
      let parser = new DOMParser();
      let dom = parser.parseFromString(
        '<!doctype html><body>' + str,
        'text/html',
      );
      return dom.body.textContent;
    },
    getMachineData() {
      this.loading = true;

      this.axios
        .get(this.api.machineData + this.id + "?"  + this.$store.getters['auth/getLanguageRest'])
        .then((res) => {
          this.title = this.domDecoder(res.data.title.rendered);
          this.body = this.domDecoder(res.data.content.rendered);

          this.getImage(res.data.featured_media);

          if (
            res.data.acf.gallery_pagine !== null &&
            res.data.acf.gallery_pagine.length > 0
          ) {
            this.galleryItems = res.data.acf.gallery_pagine;
            //this.getGallery(res.data.acf.gallery_pagine)
          }

          if (res.data.acf.catalogo != null) {
            this.catalogo = res.data.acf.catalogo;
          } else {
            this.catalogo = '';
          }

          this.loading = false;
        })
        .catch((err) => {
          console.log('Errror: ' + err);
        });
    },

    async getImage(id) {
      await this.axios
        .get(this.api.getMediaUrl + id)
        .then((res) => {
          this.headerImage = res.data.media_details.sizes;
        })
        .catch((err) => {
          console.log('Errror: ' + err);
        });
    },
  },

  created() {
    this.id = this.$route.params.id;
  },

  mounted() {
    this.getMachineData();
  },
};
</script>

<style scoped>
main {
  width: 100%;
  float: left;
}

.column img {
  max-width: 100%;
  width: 100%;
}

.column--text {
  padding: 0 10%;
}

.machine__title {
  width: 100%;
  float: left;
  background-color: var(--var-primary-color);
}

.machine__title h1 {
  padding: 50px 10px 40px;
  color: white;
  font-size: 2rem;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin: 0;
}

.machine__body,
.machine__description {
  margin-top: 30px;
  display: block;
  clear: both;
  text-align: left;
  font-size: 1.25rem;
  line-height: 150%;
}

.catalogo {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
}

.catalogo a {
  display: inline-block;
  margin-bottom: 20px;
  color: var(--var-primary-color);
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
}
</style>
